import React, {Component} from 'react';
import "./aboutus.css";  
import App from "../App"
import Chart from './barchart';

const Aboutme = () => {
    return(
        <div className="about">
            <h1 className="title-about"><u>Work and Volunteer Experience</u></h1>
            <h3>Intern - Lazer Technologies</h3>
            <h5><i>(March 2020-April 2021)</i></h5>
            <p>Helped in creation of company website, implementing front-end through React.js. I created components for the site, and helped design the overall structure of the app.</p>
            <hr/>
            <h3>Swimming Instructor - City of Vaughan (Al Palladini Community Center)</h3>
            <h5><i>(March 2020-April 2021)</i></h5>
            <p>Worked as a swimming instructor for the City of Vaughan, teaching people of all ages and of all skill levels the crucial life skill of swimming.</p>
            <hr/>
            <h3>S.T.E.A.M Volunteer - City of Vaughan (Vellore Village Library) </h3>
            <h5><i>(June 2019-January 2020)</i></h5>
            <p>Taught a variety of classes on the basics of technology principles, holding classes on how to be creative and how to express oneself through science and art.</p>
            <hr/>
            <h3>Computer Science Club Executive - Maple High School </h3>
            <h5><i>(September 2020-July 2021)</i></h5>
            <p>Created and taught a variety of classes to high school students on basic coding and coding principles. Worked with other executives to create large-scale events, such as a school-wide hackathon.</p>
            <hr/>
            <h3>Freelancer for Real Estate Agents - Woodbridge, Ontario </h3>
            <h5><i>(September 2019-January 2020)</i></h5>
            <p>Helped a number of real estate agents with designing and creating their own flyers, cards and website. Played a role in the implementation and maintainance of these items as well. </p>
            <hr/>
            <hr/>

            <h1 className="title-about"><u>Awards</u></h1>
            <h3>Winner - Engineers Without Borders 2019 (University of Toronto)</h3>
            <p>Over a weekend, me and a group of 3 other people produced a solution to a given world issue and presented it to the judges and the other 150 competitors in attendence. We produced the most viable solution, winning the overall competition. </p>
            <hr/>
            <h3>2nd Place Prize - Queen's University High School Computing Competition 2021</h3>
            <p>Submitted <a href="https://srishabh.ca/Projects">yourSpace</a> Chrome Extension to the 2021 Queen's University Computing Competition, winning the 2nd overall prize for best product.</p>
            <hr/>
            <h3>3rd Place Prize - UWaterloo CS Club Project Program 2021</h3>
            <p>Me and 3 other people produced <a href="https://srishabh.ca/Projects">TypeTile</a>, which won the 3rd place prize, from around a dozen other projects in the competition.</p>
            <hr/>
        
        <hr/>
        </div>
    )
}

export default Aboutme; 