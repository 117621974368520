import React, {Component} from 'react';
import "./projects.css";  
import App from "../App"; 
import yourSpace from "../images/safari-pinned-tab.png"
import fitflexlogo from "../images/fitflexlogobruh.png"
import { render } from '@testing-library/react';
import {Card, Button, CardGroup} from "react-bootstrap";
import { MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardFooter, MDBRow, MDBCol } from 'mdb-react-ui-kit';

const Projects = () => {
    return(
<div class="projects-stuff">
<MDBRow className='row-cols-1 row-cols-md-3 g-5'>
<MDBCol>
  <MDBCard className='h-100'>
    <MDBCardImage
      src={yourSpace}
      alt='yourSpace logo'
      position='top'
    />
    <MDBCardBody>
      <MDBCardTitle>yourSpace</MDBCardTitle>
      <MDBCardText>
      Created a Chrome Extension using HTML, CSS and JavaScript that aids users in becoming more productive while on the web.
      </MDBCardText>
    </MDBCardBody>
    <MDBCardFooter>
    <Button variant="primary" href="https://chrome.google.com/webstore/detail/yourspace/hbnjcjicdodldbfjcplllfdimjoocjmk">Download Now</Button>
    </MDBCardFooter>
  </MDBCard>
</MDBCol>
<MDBCol>
  <MDBCard className='h-100'>
    <MDBCardImage
      src="https://mms.businesswire.com/media/20210421005281/en/832238/23/RapidAPI_logo_blue.jpg"
      alt='RapidAPI logo'
      position='top'
    />
    <MDBCardBody>
      <MDBCardTitle>API on RapidAPI</MDBCardTitle>
      <MDBCardText>
      Build a variety of usable APIs, published on RapidAPI. These APIs retrieve the top news about Canadian Federal Politics, American Federal Politics and Fitness, respectively.
      </MDBCardText>
    </MDBCardBody>
    <MDBCardFooter>
    <Button variant="primary" href="https://rapidapi.com/user/ri3habh">Check out the APIs</Button>
    </MDBCardFooter>
  </MDBCard>
</MDBCol>
<MDBCol>
  <MDBCard className='h-100'>
    <MDBCardImage
      src={fitflexlogo}
      alt='FIT+FLEX logo'
      position='top'
    />
    <MDBCardBody>
      <MDBCardTitle>FIT+FLEX</MDBCardTitle>
      <MDBCardText>
      Website created through React.js, helping users adhere to their fitness goals by providing 1300 excercises, and a curation of fitness calculators and fitness articles.
      </MDBCardText>
    </MDBCardBody>
    <MDBCardFooter>
    <Button variant="primary" href="https://fitflex.ca/">Check out the app!</Button>
    </MDBCardFooter>
  </MDBCard>
</MDBCol>
<MDBCol>
  <MDBCard className='h-100'>
    <MDBCardBody>
      <MDBCardTitle>TypeTile</MDBCardTitle>
      <MDBCardText>
      Web game produced in HTML, CSS and JavaScript. I helped design the game, and helped decide how it's going to run. I then helped implement the back-end of the game, allowing users to keep track of their score, and added a settings page so users can customise their gameplay.
      </MDBCardText>
    </MDBCardBody>
    <MDBCardFooter>
    <Button variant="primary" href="https://github.com/ri3habh/TypeTile">Check out the repo!</Button>
    </MDBCardFooter>
  </MDBCard>
</MDBCol>
</MDBRow>

</div>
            
    )
}

export default Projects; 

